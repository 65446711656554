export const initialState = {
  medicines: [],
  documents: [],
  auth: {
    email: "",
    password: "",
    confirmPassword: "",
    hasBeenVerified: false,
    accessCode: "",
  },
  enrollment: {
    agreeToConsent: [],
    firstName: "",
    lastName: "",
    sex: "",
    mobileNumber: "",
    employeeNumber: "",
    department: "",
    emergencyContactName: "",
    emergencyContactMobileNumber: "",
    address: {
      streetAddress: "",
      city: "",
      province: "",
      barangay: "",
    },
  },
  intake: {
    consultReason: [],
    isCurrentlyTakingMedicines: "",
    landlineNumber: "",
    lastDoctorVisit: "",
    medicalHistory: "",
    otherNotes: "",
    specificConsultReason: "",
    specifyMedicines: "",
    supportingDocuments: "",
    viberNumber: "",
  },
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: false,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  agreeToConsent: {
    mentalHealth: [],
  },
  pageBreak: false
}
