import React, { useReducer } from "react"

import { ConsultationReducer } from "./ConsultationReducer"
import { useAirtableConsultationFormFields } from "./hooks/useAirtableConsultationFormFields"

import { generateInitialValues } from "../../../services/context"

const ConsultationContext = React.createContext()

const ConsultationProvider = ({ children }) => {
  let formFields = useAirtableConsultationFormFields()

  let [consultationState, consultationDispatch] = useReducer(
    ConsultationReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      documents: [],
      toast: {
        isActive: false,
        message: null,
        color: null,
      },
    }
  )

  return (
    <ConsultationContext.Provider
      value={{ consultationState, consultationDispatch }}
    >
      {children}
    </ConsultationContext.Provider>
  )
}

export { ConsultationContext, ConsultationProvider }
