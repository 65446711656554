import { useStaticQuery, graphql } from "gatsby"

import { flattenNode } from "../../../services/arrays"

export const useAirtableMedEnsureFormFields = () => {
  let formFields = useStaticQuery(graphql`
    query AirtableMedEnsureFormFields {
      allAirtableallTeleconsultFormFields(
        sort: { fields: data___Order }
        filter: { data: { Show_On_Website: { eq: true } } }
      ) {
        nodes {
          data {
            Type
            Required
            Name
            Add_Divider_After_Field
            Date_Config
            Field_Names
            Helper
            Follow_Up_Questions
            Follow_Up_Questions_Name
            Is_Follow_Up_Question
            Label
            Module
            Options
            Order
            Reference_Answer
            Reference_Question
            Reference_Question_Name
            Section
            Section_Order
            Section_Message
            Show_On_Website
            Summary_Section
            Summary_Label
            Validation
            Initial_Values
          }
        }
      }
    }
  `)

  return flattenNode(formFields?.allAirtableallTeleconsultFormFields)
}
