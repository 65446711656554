import React, { useReducer } from "react"

// import { initialState } from "../../../context/initialState"
import { MedEnsureReducer } from "./MedEnsureReducer"
import { useAirtableMedEnsureFormFields } from "../hooks/useAirtableMedEnsureFormFields"

import { generateInitialValues } from "../../../services/context"

const MedEnsureContext = React.createContext()

const MedEnsureProvider = ({ children }) => {
  let formFields = useAirtableMedEnsureFormFields()

  let [medEnsureState, medEnsureDispatch] = useReducer(
    MedEnsureReducer,
    {
      ...generateInitialValues({ fields: formFields }),
      toast: {
        isActive: false,
        message: null,
        color: null,
      },
    }
  )

  return (
    <MedEnsureContext.Provider
      value={{ medEnsureState, medEnsureDispatch }}
    >
      {children}
    </MedEnsureContext.Provider>
  )
}

export { MedEnsureContext, MedEnsureProvider }
