import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "context/services/context"

import { isObjectEmpty } from "services/general"
import { clearObject } from "../../../services/clearObject"

export const initialState = {}

const forms = {
  SAVE_MEDENSURE: "SAVE_MEDENSURE",
  RESET_DETAILS: "RESET_DETAILS",
}

const session = {
  SAVE_CONTEXT_TO_SESSION: "SAVE_CONTEXT_TO_SESSION",
  GET_CONTEXT_FROM_SESSION: "GET_CONTEXT_FROM_SESSION",
  REMOVE_CONTEXT_FROM_SESSION: "REMOVE_CONTEXT_FROM_SESSION",
}

export const MedEnsureReducer = (state, action) => {
  let toBeSaved = {}
  switch (action.type) {
    case forms.SAVE_MEDENSURE:
      toBeSaved = { ...state, ...action.payload }
      saveContextToSession({ ...toBeSaved })
      return toBeSaved

    case session.SAVE_CONTEXT_TO_SESSION:
      toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case session.GET_CONTEXT_FROM_SESSION:
      let returnState = { ...state, ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }

      return {
        ...returnState,
        modal: {
          ...initialState.modal,
        },
      }
    case session.REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...clearObject(state),
      }

    case forms.RESET_DETAILS:
    default:
      return { ...clearObject(state) }
  }
}
